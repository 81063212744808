*{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Commissioner', sans-serif;
}

/*OVERLAY*/

.overlay{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content{
  width: 90vw;
  max-width: 1000px;
  height: 90vh;
  overflow-y: hidden;
  padding: 50px;
  padding-top: 100px;
  box-sizing: border-box;
  background-color: #ffffff;
  position: relative;
}

.overlay-content button{
  background-color: #000000;
  color: #ffffff;
  font-size: 20px;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
}

.overlay-content h1{
  font-size: 40px;
  font-weight: normal;
}

.overlay-content h2{
  font-size: 20px;
  margin-top: 40px;
}

.overlay-content p{
  font-size: 14px;
  margin-top: 10px;
}

.overlay-content ul li{
  font-size: 14px;
  margin-top: 10px;
}

.overlay-content div{
  height: 78vh;
  overflow-y: scroll;
}

/*NAVIGATION SECTION*/

.nav{
  width: 100vw;
  padding-top: 2vh;
  background-color: #ffffff;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.nav h1{
  text-align: center;
  margin-bottom: 0.5vh;
  font-weight: normal;
  font-size: 40px;
  font-family: 'Antic Didone', serif;
}

.menu{
  background-color: #332d2b;
  padding: 1vh 0;
  display: flex;
  justify-content: center;
}

.menu button{
  background: none;
  border: none;
  color: #ffffff;
  margin: 0 2vw;
  font-family: 'Antic Didone', serif;
  cursor: pointer;
}

/*HERO SECTION*/

.hero{
  margin-top: 15vh;
}

.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}

#flex-bottom{
  background-color: #803D3B;
  margin-top: -20vh;
  padding: 15vh 0;
}

.text-block{
  width: 30vw;
}

.image{
  background-size: cover;
  background-position: center;
}

#hero-img{
  width: 30vw;
  height: 100vh;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

#hero-text{
  padding-bottom: 50vh;
  margin-right: 5vw;
}

#hero-text button{
  background-color: #E6160D;
  padding: 10px;
  border: none;
  color: #ffffff;
  margin-top: 1vh;
  cursor: pointer;
  transition: 0.25s;
}

#hero-text button:hover{
  background-color: #000000;
}

#map{
  width: 40vw;
  padding-top: 33.5%;
  opacity: 0.2;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
}

#map-text{
  margin-left: -10vw;
  color: #ffffff;
  z-index: 2;
}

#map-text h3{
  font-size: 40px;
  font-family: 'Antic Didone', serif;
  font-weight: normal;
}

/*SERVICES SECTION*/

.services{
  padding: 10vh 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  width: 60vw;
  margin: auto;
  overflow: visible;
  row-gap: 3vh;
}

.tile{
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 3vh;
  border-radius: 10px;
  box-sizing: border-box;
  width: 90%;
  width: 15vw;
  text-align: center;
  height: 25vh;
  overflow: hidden;
  margin: 0 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tile h1{
  color: #803D3B;
  font-size: 1.2vw;
}

.tile p{
  width: 100%;
  margin: auto;
}

.icon{
  width: 30%;
  padding-bottom: 30%;
  margin: auto;
}

/*CONTACT SECTION*/

.contact{
  background-color: #332D2B;
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side{
  width: 30vw;
  margin: 0 2.5vw;
}

.side h4{
  font-size: 7vw;
  color: #ffffff;
  font-weight: normal;
  font-family: 'Antic Didone', serif;
}

.side p{
  color: #803D3B;
  font-size: 2vw;
  margin-top: 20px;
}

.side form{
  width: 100%;
}

.side form input[type=text]{
  width: 100%;
  padding: 10px;
  font-size: 20px;
  margin: 10px 0;
  background-color: #262626;
  border: none;
  color: #ffffff;
  box-sizing: border-box;
}

.side form div{
  display: flex;
}

.side form div input[type=text]:first-child{
  width: 20%;
  margin-right: 20px;
}

select{
  width: 100%;
  padding: 10px;
  font-size: 20px;
  margin: 10px 0;
  background-color: #262626;
  border: none;
  color: #ffffff;
  box-sizing: border-box;
}

.side form textarea{
  width: 100%;
  padding: 10px;
  font-size: 20px;
  margin: 10px 0;
  background-color: #262626;
  border: none;
  color: #ffffff;
  box-sizing: border-box;
  resize: none;
  height: 8rem;
}

#form-text{
  font-size: 14px;
  color: #ffffff;
}

.side form input[type=submit]{
  background-color: #803D3B;
  border: none;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Antic Didone', serif;
}

.side form input[type=submit]:hover{
  color: #803D3B;
  background-color: #ffffff;
}

/*FOOTER SECTION*/

.footer{
  background-color: #1F1F1F;
  height: 15vh;
  padding: 5vh 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  margin-top: -25vh;
  z-index: 0;
}

.footer div{
  max-width: 350px;
  z-index: 2;
}

.footer div h5{
  color: #ffffff;
  font-size: 24px;
  font-weight: normal;
  font-family: 'Antic Didone', serif;
}

.footer div h4{
  color: #ffffff;
  font-size: 40px;
  font-weight: normal;
  font-family: 'Antic Didone', serif;
}

.footer div p{
  color: #ffffff;
  font-size: 14px;
}

.footer div form{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 5px;
}

.footer div form select{
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
  border: none;
  height: 30px;
  margin-bottom: 10px;
}

.footer div form input[type=text]{
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
  border: none;
  height: 30px;
  margin-bottom: 10px;
}

#email-footer{
  width: calc(100% - 30px);
}

.footer div form input[type=submit]{
  background-color: #803D3B;
  border: none;
  color: #ffffff;
  width: 30px;
  height: 30px;
}

.marquee-text{
  font-weight: normal;
  font-size: 23vh;
  font-family: 'Antic Didone', serif;
}

@media(max-width: 1600px){
  .services{
    width: 90vw;
  }
  .tile{
    width: 40vw;
    height: 40vh;
  }
  .icon{
    width: 50%;
    padding-bottom: 50%;
  }
  .tile p{
    width: 50%;
  }
}

@media(max-width: 1300px){
  /*HERO SECTION*/
  .hero{
    margin-top: 11vh;
  }
  .flex{
    flex-direction: column-reverse;
  }
  #hero-text{
    position: absolute;
    top: 50vh;
    left: 0;
    padding: 5vw;
    background-color: #332D2B;
    color: #ffffff;
    width: 100vw;
    box-sizing: border-box;
  }
  #hero-img{
    width: 95vw;
    height: 90vh;
  }
  #flex-bottom{
    padding-top: 30vh;
    padding-bottom: 20vh;
  }
  #map-text{
    width: 90vw;
    padding: 5vw;
    box-sizing: border-box;
  }
  #map{
    width: 90vw;
    padding-top: 60%;
    margin-top: -25vh;
  }
  /*CONTACT SECTION*/
  .contact{
    flex-direction: column;
    height: auto;
    padding: 10vh 0;
  }
  .side{
    width: 90vw;
  }
  .side h4{
    font-size: 19vw;
  }
  .side p{
    font-size: 6vw;
  }
  .side form{
    margin-top: 5vh;
  }
  .side form div input[type=text]:first-child{
    width: 40%;
  }
  /*FOOTER SECTION*/
  .footer{
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    width: 90vw;
    padding: 5vh 5vw;
  }
  .footer div{
    margin-top: 3vh;
  }
  .footer div:first-of-type{
    margin-top: 0;
  }
}

@media(max-width: 1000px){
  .tile{
    width: 100%;
    height: 50vh;
    padding: 4vh;
    height: auto;
  }
  .tile h1{
    font-size: 6vw;
  }
  .tile p{
    width: 70%;
  }
}